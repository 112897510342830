import React from "react";
import { Link } from "react-router-dom";

export default class HeroOne extends React.Component {
  componentDidMount() {
    const $ = window.$;

    if ($(".slider-carousel").length) {
      $(".slider-carousel").owlCarousel({
        animateOut: "fadeOut",
        animateIn: "fadeIn",
        loop: true,
        margin: 0,
        nav: false,
        singleItem: true,
        smartSpeed: 500,
        autoplay: true,
        autoplayTimeout: 6000,
        navText: [
          '<span class="icon-right-arrow"></span>',
          '<span class="icon-right-arrow"></span>',
        ],
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1024: {
            items: 1,
          },
        },
      });
    }
  }
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    return (
      <>
        <section className="slider-one-sec style3">
          <div className="slider-carousel owl-carousel owl-theme">
            {/* Start Slider One Single */}
            <div className="slider-one__single">
              <div
                className="image-layer"
                style={{
                  backgroundImage: `url("https://ik.imagekit.io/w4supc8gx/SK/man-electrical-technician-working-switchboard-with-fuses-uses-tablet.webp?updatedAt=1697305066085")`,
                }}
              ></div>
              <div className="container">
                <div className="row clearfix">
                  <div className="col-xl-12">
                    <div className="slider-one__single-content">
                      <h3>Comprehensive Repair Services</h3>
                      <h2>Spark Kuwait Generator Maintenance Company,</h2>
                      <p>
                        At Spark Kuwait Generator Maintenance Company,
                        <br /> we're dedicated ot engineering excellence in
                        Kuwait.
                      </p>
                      {/* <div className="btn-box">
                        <Link
                          to={process.env.PUBLIC_URL + `/`}
                          className="thm-btn"
                          data-text="Read More +"
                        >
                          Read More +
                        </Link>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Slider One Single */}

            {/* Start Slider One Single */}
            <div className="slider-one__single">
              <div
                className="image-layer"
                style={{
                  backgroundImage: `url("https://ik.imagekit.io/w4supc8gx/SK/plant-features-large-electric-diesel-generator-generative-ai-case-emergency.webp?updatedAt=1697305064823")`,
                }}
              ></div>
              <div className="container pl-0">
                <div className="row clearfix">
                  <div className="col-xl-12">
                    <div className="slider-one__single-content text-center">
                      <h3>Skilled professionals</h3>
                      <h2>Generator maintenance</h2>
                      <p>
                        Maintenance includes regular inspections, servicing,{" "}
                        <br /> and repairs to ensure efficient, reliable
                        operation and extend lifespan."
                      </p>
                      {/* <div className="btn-box">
                        <Link
                          to={process.env.PUBLIC_URL + `/`}
                          className="thm-btn"
                          data-text="Read More +"
                        >
                          Read More +
                        </Link>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Slider One Single */}

            {/* Start Slider One Single */}
            <div className="slider-one__single">
              <div
                className="image-layer"
                style={{
                  backgroundImage: `url("https://ik.imagekit.io/w4supc8gx/SK/person-working-building-construction.webp?updatedAt=1697305069171")`,
                }}
              ></div>
              <div className="container">
                <div className="row clearfix">
                  <div className="col-xl-12">
                    <div className="slider-one__single-content">
                      <h3>Spark Kuwait Generator Maintenance</h3>
                      <h2>
                        Your Trusted Partner in <br /> Reliable VC Power
                        Solutions!
                      </h2>
                      <p>
                        With years of expertise, our dedicated team offers
                        comprehensive maintenance, repair, and installation
                        services for generators of all sizes and brands.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Slider One Single */}
          </div>
        </section>
      </>
    );
  }
}
