import React from "react";
import { Link } from "react-router-dom";

const Nav = () => {
  const handleClickScroll = () => {
    console.log("handleClickScroll");
    const element = document.getElementById("servicesSection");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="main-menu text-center">
      <nav>
        <ul className="main-menu__list">
          <li>
            <Link to={process.env.PUBLIC_URL + `/`}>Home</Link>
            {/* <ul>
                            <li><Link to={process.env.PUBLIC_URL + `/`}>Home</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/home-two`}>Home Two</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/home-three`}>Home Three</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/home-four`}>Home Four</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/home-five`}>Home Five</Link></li>
                            <li className="dropdown">
                                <Link to={process.env.PUBLIC_URL + `/`}>Header Styles</Link>
                                <ul>
                                    <li><Link to={process.env.PUBLIC_URL + `/`}>Header One</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + `/home-two`}>Header Two</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + `/home-three`}>Header Three</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + `/home-four`}>Header Four</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + `/home-five`}>Header Five</Link></li>
                                </ul>
                            </li>
                        </ul> */}
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + `/about-us`}>About us</Link>
            {/* <ul>
                            <li><Link to={process.env.PUBLIC_URL + `/about-one`}>About One</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/about-two`}>About Two</Link></li>
                        </ul> */}
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + `/`} onClick={handleClickScroll}>
              Services
            </Link>
          </li>
          {/* <li className="dropdown">
                        <Link to={process.env.PUBLIC_URL + `#`}>Pages</Link>
                        <ul>
                            <li><Link to={process.env.PUBLIC_URL + `/portfolio`}>Portfolio</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/portfolio-details`}>Portfolio Details</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/team`}>Team</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/team-details`}>Team Details</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/testimonials`}>Testimonials</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/pricing`}>Pricing</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/faq`}>Faq</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/error`}>Error</Link></li>
                        </ul>
                    </li> */}
          {/* <li className="dropdown">
                        <Link to={process.env.PUBLIC_URL + `#`}>Blog</Link>
                        <ul>
                            <li><Link to={process.env.PUBLIC_URL + `/blog`}>Blog</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/blog-grid`}>Blog Grid</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/blog-list`}>Blog List</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/blog-standard`}>Blog Standard</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/blog-details`}>Blog Details</Link></li>
                        </ul>
                    </li> */}
          <li>
            <Link to={process.env.PUBLIC_URL + `/contact`}>Contact us</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Nav;
