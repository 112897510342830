import React from "react";
import { Link } from "react-router-dom";

export default class AboutFour extends React.Component {
  componentDidMount() {
    const $ = window.$;

    if ($(".video-popup").length) {
      $(".video-popup").magnificPopup({
        type: "iframe",
        mainClass: "mfp-fade",
        removalDelay: 160,
        preloader: true,

        fixedContentPos: false,
      });
    }
  }
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    return (
      <>
        <div className="about-three">
          <div className="container">
            <div className="row">
              <div className="col-xl-6">
                <div className="about-three__img">
                  <div className="inner">
                    <img
                      src={`https://ik.imagekit.io/w4supc8gx/SK/green-auxiliary-diesel-generator-emergency-electric-power-outage-factory.webp?updatedAt=1697305069380`}
                      alt="#"
                    />
                    {/* <div
                      className="about-three__img-video wow zoomIn"
                      data-wow-delay="100ms"
                    >
                      <a
                        href="https://www.youtube.com/watch?v=0O2aH4XLbto"
                        className="video-popup"
                      >
                        <div className="about-three__img-video-icon">
                          <span className="fa fa-play"></span>
                          <i className="ripple"></i>
                        </div>
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="about-three__content">
                  <div className="sec-title">
                    <div className="sec-title__tagline">
                      <h6> ABOUT US </h6> <span className="right"></span>
                    </div>
                    <h2 className="sec-title__title">
                      Comprehensive Repair Services
                    </h2>
                  </div>

                  <div className="about-three__content-inner">
                    <div className="text1">
                      <p>
                        At Spark Kuwait Generator Maintenance Company, we're
                        dedicated to engineering excellence in Kuwait. With a a
                        strong reputation as industry leaders, we specialize in
                        the maintenance, repair, rewinding, and overhauling of
                        AC/DC motors, transformers, submersible pumps,
                        generators, and a wide range of industrial equipment.
                        <br />
                        <br />
                        Our team comprises highly skilled technicians and
                        experienced supervisors who are committed to delivering
                        top-tier service.
                      </p>
                    </div>
                    <div className="text2">
                      <h4>
                        We take pride in our state-of-the-art facilities, which
                        provide comprehensive support and allow us to bring our
                        expertise to every customer.
                      </h4>
                    </div>
                    <ul className="about-three__content-list">
                      <li>
                        <div className="icon-box">
                          <span className="icon-tick"></span>
                        </div>
                        <div className="text-box">
                          <p> AC/DC motors</p>
                        </div>
                      </li>
                      <li>
                        <div className="icon-box">
                          <span className="icon-tick"></span>
                        </div>
                        <div className="text-box">
                          <p> Generator</p>
                        </div>
                      </li>
                      <li>
                        <div className="icon-box">
                          <span className="icon-tick"></span>
                        </div>
                        <div className="text-box">
                          <p>Skilled professionals</p>
                        </div>
                      </li>
                      <li>
                        <div className="icon-box">
                          <span className="icon-tick"></span>
                        </div>
                        <div className="text-box">
                          <p>Maintenance</p>
                        </div>
                      </li>
                    </ul>
                    {/* <div className="about-three__content-btn">
                      <Link
                        href={`${process.env.PUBLIC_URL}/about-one`}
                        className="thm-btn"
                        data-text="Discover More +"
                      >
                        Discover More +
                      </Link>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
