import React from "react";
import { Link } from "react-router-dom";

export default class FeatureFour extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    return (
      <>
        {/* Start Feature Four */}
        <section id="servicesSection" className="feature-four">
          <div className="container">
            <div className="sec-title text-center">
              <div className="sec-title__tagline">
                <span className="left"></span>
                <h6>OUR SERVICES</h6> <span className="right"></span>
              </div>
              <h2 className="sec-title__title">Our Best Services</h2>
            </div>
            <div className="row">
              {/* Start Feature Four Single */}
              <div className="col-xl-4">
                <div className="feature-four__single">
                  <div className="feature-four__single-img">
                    <img
                      src={`https://ik.imagekit.io/w4supc8gx/SK/engineer-electrician-check-substation-construction-process.webp?updatedAt=1697305066412`}
                      alt="#"
                    />
                  </div>
                </div>
              </div>
              {/* End Feature Four Single */}

              {/* Start Feature Four Single */}
              <div className="col-xl-4">
                <div className="feature-four__single style2 text-center">
                  <div className="feature-four__single-icon">
                    <span className="icon-interior-design-1"></span>
                  </div>
                  <div className="feature-four__single-content">
                    <span>EXPLORE THE SERVICES</span>
                    <h2>
                      <Link to={process.env.PUBLIC_URL + `/`}>Generator</Link>
                    </h2>
                    <p>
                      Generator maintenance includes regular inspections,
                      servicing, and repairs to ensure efficient, reliable
                      operation and extend lifespan."
                    </p>
                  </div>
                </div>
              </div>
              {/* End Feature Four Single */}

              {/* Start Feature Four Single */}
              <div className="col-xl-4">
                <div className="feature-four__single">
                  <div className="feature-four__single-img">
                    <img
                      src={`https://ik.imagekit.io/w4supc8gx/SK/cultivator-hd-8k-wallpaper-stock-photographic-image.webp?updatedAt=1697305064641`}
                      alt="#"
                    />
                  </div>
                </div>
              </div>
              {/* End Feature Four Single */}

              {/* Start Feature Four Single */}
              <div className="col-xl-4">
                <div className="feature-four__single style2 text-center">
                  <div className="feature-four__single-icon">
                    <span className="icon-crane"></span>
                  </div>
                  <div className="feature-four__single-content">
                    <span>EXPLORE THE SERVICES</span>
                    <h2>
                      <Link to={process.env.PUBLIC_URL + `/`}>
                        AC/DC motors
                      </Link>
                    </h2>
                    <p>
                      AC/DC motors are electrical machines that convert
                      electrical energy into mechanical energy.
                    </p>
                  </div>
                </div>
              </div>
              {/* End Feature Four Single */}

              {/* Start Feature Four Single */}
              <div className="col-xl-4">
                <div className="feature-four__single">
                  <div className="feature-four__single-img">
                    <img
                      src={`https://ik.imagekit.io/w4supc8gx/SK/plant-features-large-electric-diesel-generator-generative-ai-case-emergency.webp?updatedAt=1697305064823`}
                      alt="#"
                    />
                  </div>
                </div>
              </div>
              {/* End Feature Four Single */}

              {/* Start Feature Four Single */}
              <div className="col-xl-4">
                <div className="feature-four__single style2 text-center">
                  <div className="feature-four__single-icon">
                    <span className="icon-painting"></span>
                  </div>
                  <div className="feature-four__single-content">
                    <span>EXPLORE THE SERVICES</span>
                    <h2>
                      <Link to={process.env.PUBLIC_URL + `/`}>Maintenance</Link>
                    </h2>
                    <p>
                      Maintenance is the routine process of inspecting,
                      servicing, and repairing assets to ensure their efficient
                      and safe operation. Repair is the act of fixing or
                      restoring damaged items, aiming to return them to their
                      functional state.
                    </p>
                  </div>
                </div>
              </div>
              {/* End Feature Four Single */}
            </div>
          </div>
        </section>
        {/* End Feature Four */}
      </>
    );
  }
}
