import React from "react";
import { Link } from "react-router-dom";

export default class FeatureFive extends React.Component {
  componentDidMount() {
    const $ = window.$;

    if ($(".tabs-box").length) {
      $(".tabs-box .tab-buttons .tab-btn").on("click", function (e) {
        e.preventDefault();
        var target = $($(this).attr("data-tab"));

        if ($(target).is(":visible")) {
          return false;
        } else {
          target
            .parents(".tabs-box")
            .find(".tab-buttons")
            .find(".tab-btn")
            .removeClass("active-btn");
          $(this).addClass("active-btn");
          target
            .parents(".tabs-box")
            .find(".tabs-content")
            .find(".tab")
            .fadeOut(0);
          target
            .parents(".tabs-box")
            .find(".tabs-content")
            .find(".tab")
            .removeClass("active-tab");
          $(target).fadeIn(300);
          $(target).addClass("active-tab");
        }
      });
    }

    if ($(".feature-three__history-carousel").length) {
      $(".feature-three__history-carousel").owlCarousel({
        loop: false,
        margin: 30,
        nav: false,
        smartSpeed: 500,
        autoHeight: false,
        autoplay: true,
        dots: false,
        autoplayTimeout: 6000,
        navText: [
          '<i className="fa fa-angle-double-left" aria-hidden="true"></i>',
          '<i className="fa fa-angle-double-right" aria-hidden="true"></i>',
        ],
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          992: {
            items: 2,
          },
          1200: {
            items: 3,
          },
          1350: {
            items: 3,
          },
        },
      });
    }
  }
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    return (
      <>
        <section className="feature-three-sec clearfix">
          <div
            className="feature-three__bg"
            style={{
              backgroundImage:
                "url(" +
                publicUrl +
                "assets/images/backgrounds/features-v1-bg.jpg)",
            }}
          ></div>
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="feature-three__title">
                  <div className="sec-title">
                    <div className="sec-title__tagline">
                      <h6>More About Us</h6> <span className="right"></span>
                    </div>
                    <h2 className="sec-title__title">
                      Welcome to Spark Kuwait Generator Maintenance
                    </h2>
                    <p className="sec-title__text">
                      With a team of skilled professionals, cutting-edge
                      technology, and a commitment to excellence, we offer a
                      comprehensive range of services tailored to meet your
                      specific needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="feature-three__tab tabs-box">
                  <div className="feature-three__tab-button">
                    <ul className="tab-buttons clearfix">
                      {/* <li data-tab="#history" className="tab-btn active-btn">
                        <h3>Our History</h3>
                      </li> */}
                      {/* <li data-tab="#growth " className="tab-btn">
                        <h3>Our Growth</h3>
                      </li> */}
                      <li data-tab="#mission" className="tab-btn active-tab">
                        <h3>Our Mission</h3>
                      </li>
                      <li data-tab="#vision" className="tab-btn">
                        <h3>Our Vision</h3>
                      </li>
                    </ul>
                  </div>

                  <div className="tabs-content">
                    {/* Start Tab */}

                    {/* End Tab */}

                    {/* Start Tab */}

                    {/* End Tab */}

                    {/* Start Tab */}
                    <div className="tab active-tab" id="mission">
                      <div className="feature-three__tab-content-item">
                        <div className="tab-content-inner">
                          <div className="row">
                            <div className="col-xl-5 col-lg-6">
                              <div className="tab-content-inner-text">
                                <h2>Our Mission</h2>
                                <p>
                                  At Spark Kuwait Generator Maintenance Company,
                                  we're on a mission to provide unmatched
                                  expertise in engineering, ensuring seamless
                                  equipment operation for Kuwait's industries.
                                  Our highly skilled technicians,
                                  state-of-the-art facilities, and dedicated
                                  service teams swiftly resolve issues,
                                  empowering industries with reliable solutions
                                  and setting new standards in excellence.
                                </p>
                                {/* <div className="btn-box">
                                  <Link
                                    to={process.env.PUBLIC_URL + `/`}
                                    className="thm-btn"
                                    data-text="Get Started +"
                                  >
                                    Get Started +
                                  </Link>
                                </div> */}
                              </div>
                            </div>
                            <div className="col-xl-7 col-lg-6">
                              <div className="tab-content-inner-img">
                                <img
                                  src={
                                    publicUrl +
                                    "assets/images/resources/feature-v3-img2.jpg"
                                  }
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End Tab */}

                    {/* Start Tab */}
                    <div className="tab" id="vision">
                      <div className="feature-three__tab-content-item">
                        <div className="tab-content-inner">
                          <div className="row">
                            <div className="col-xl-5 col-lg-6">
                              <div className="tab-content-inner-text">
                                <h2>Our Vision</h2>
                                <p>
                                  To be Kuwait's foremost engineering solutions
                                  provider, setting industry standards ni
                                  maintenance, repair, and overhaul services,
                                  while continuously enhancing our technical
                                  prowess.
                                </p>
                                {/* <div className="btn-box">
                                  <Link
                                    to={process.env.PUBLIC_URL + `/`}
                                    className="thm-btn"
                                    data-text="Get Started +"
                                  >
                                    Get Started +
                                  </Link>
                                </div> */}
                              </div>
                            </div>
                            <div className="col-xl-7 col-lg-6">
                              <div className="tab-content-inner-img">
                                <img
                                  src={
                                    publicUrl +
                                    "assets/images/resources/feature-v3-img3.jpg"
                                  }
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End Tab */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
