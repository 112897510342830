import React from "react";
import { Link } from "react-router-dom";

const handleClickScroll = () => {
  console.log("handleClickScroll");
  const element = document.getElementById("form-container");
  if (element) {
    // 👇 Will scroll smoothly to the top of the next section
    element.scrollIntoView({ behavior: "smooth" });
  }
};

export default class FooterOne extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    return (
      <>
        <footer className="footer-one-sec">
          <div
            className="footer-one__pattern"
            style={{
              backgroundImage:
                "url(" +
                publicUrl +
                "assets/images/pattern/footer-v1-pattern.png)",
            }}
          ></div>
          <div className="footer-one__top">
            {/* <div
              className="footer-one__top-img"
              style={{
                backgroundImage:
                  "url(" +
                  publicUrl +
                  "assets/images/resources/footer-v1-img3.png)",
              }}
            ></div> */}
            <div className="container">
              <div className="row">
                <div className="col-xl-12">
                  <div className="footer-one__top-inner">
                    <div className="row">
                      <div
                        className="col-xl-4 col-lg-6 col-md-6 wow animated fadeInUp"
                        data-wow-delay="0.1s"
                      >
                        <div className="footer-widget__column footer-widget__about">
                          <div className="footer-widget__about-logo">
                            <Link to="/">
                              <img
                                src={
                                  "https://ik.imagekit.io/o0jwga39e/SK/skLogov2.png?updatedAt=1696761089068"
                                }
                                alt="Logo"
                                style={{ width: "150px" }}
                              />
                            </Link>
                          </div>

                          <p className="footer-widget__about-text">
                            Comprehensive Repair Services
                            <br />
                            At Spark Kuwait Generator Maintenance Company, we're
                            dedicated ot engineering excellence ni Kuwait.
                          </p>

                          <div className="footer-widget__about-social-link">
                            <ul>
                              <li>
                                <a href="https://facebook.com">
                                  <span className="first icon-facebook-app-symbol"></span>
                                  <span className="second icon-facebook-app-symbol"></span>
                                </a>
                              </li>

                              <li>
                                <a href="https://twitter.com">
                                  <span className="first icon-twitter"></span>
                                  <span className="second icon-twitter"></span>
                                </a>
                              </li>

                              <li>
                                <a href="https://pinterest.com">
                                  <span className="first icon-pinterest"></span>
                                  <span className="second icon-pinterest"></span>
                                </a>
                              </li>

                              <li>
                                <a href="https://linkedin.com">
                                  <span className="first icon-linkedin"></span>
                                  <span className="second icon-linkedin"></span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div
                        className="col-xl-2 col-lg-6 col-md-6 wow animated fadeInUp"
                        data-wow-delay="0.3s"
                      >
                        <div className="footer-widget__column footer-widget__services">
                          <h2 className="footer-widget__title">Our Services</h2>
                          <ul className="footer-widget__services-list">
                            <li className="footer-widget__services-list-item">
                              <Link
                                to={process.env.PUBLIC_URL + `/`}
                                onClick={handleClickScroll}
                              >
                                AC/DC motors
                              </Link>
                            </li>
                            <li className="footer-widget__services-list-item">
                              <Link
                                to={process.env.PUBLIC_URL + `/`}
                                onClick={handleClickScroll}
                              >
                                Generator
                              </Link>
                            </li>
                            <li className="footer-widget__services-list-item">
                              <Link
                                to={process.env.PUBLIC_URL + `/`}
                                onClick={handleClickScroll}
                              >
                                Skilled professionals
                              </Link>
                            </li>
                            <li className="footer-widget__services-list-item">
                              <Link
                                to={process.env.PUBLIC_URL + `/`}
                                onClick={handleClickScroll}
                              >
                                Maintenance
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div
                        className="col-xl-2 col-lg-6 col-md-6 wow animated fadeInUp"
                        data-wow-delay="0.5s"
                      >
                        <div className="footer-widget__column footer-widget__explore">
                          <h2 className="footer-widget__title">Our Explore</h2>
                          <ul className="footer-widget__explore-list">
                            <li className="footer-widget__explore-list-item">
                              <Link to="/about-us">About us</Link>
                            </li>
                            <li className="footer-widget__explore-list-item">
                              <Link
                                to={process.env.PUBLIC_URL + `/`}
                                onClick={handleClickScroll}
                              >
                                Services
                              </Link>
                            </li>
                            <li className="footer-widget__explore-list-item">
                              <Link to="/contact">Contact us</Link>
                            </li>
                            <li className="footer-widget__explore-list-item">
                              <Link to="/">Terms and Privacy</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-one__bottom clearfix">
            <div className="container">
              <div className="row">
                <div className="col-xl-12">
                  <div className="footer-one__bottom-inner">
                    <div className="footer-one__bottom-text">
                      <p>
                        Copyright &copy;2023. All rights reserved to{" "}
                        <Link to="/">SPARK KUWAIT ENGINEERING COMPANY</Link>
                      </p>
                    </div>

                    {/* <div className="footer-one__bottom-list">
                      <ul>
                        <li>
                          <Link to="/">Terms & Condition </Link>
                        </li>
                        <li>
                          <Link to="/"> Services</Link>
                        </li>
                        <li>
                          <Link to="/">Careers</Link>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}
